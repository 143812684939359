export const FETCH_STORY_PUBLISH_STATUS = 'fetchStoryPublishStatus';
export const UNPUBLISH_STORY = 'unpublishStory';
export const UPDATE_STORY_CONTENT = 'updateStoryContent';
export const UPDATE_STORY_STATUS = 'updateStoryStatus';
export const UPDATE_STORY_TITLE = 'updateStoryTitle';
export const UPDATE_STORY_SLUG = 'updateStorySlug';
export const UPDATE_STORY_PUBLISH_STATUS = 'updateStoryPublishStatus';
export const UPDATE_STORY_HAS_UNCONFIRMED_CHANGES = 'updateStoryHasUnconfirmedChanges';
export const GET_STORY = 'getStory';
export const GET_STORY_REPORT = 'getStoryReport';
export const PROCESSING_STORY_PROPERTY = 'processingStoryProperty';
export const CLEAR_STORY_STATUS_PROPERTY = 'clearStoryStatusProperty';

export const DELETE_STORY = 'deleteStory';

export const UPDATE_STORY_KEYWORD = 'updateStoryKeyword';

export const UPDATE_STORY_EXCERPT = 'updateStoryExcerpt';

export const UPDATE_STORY_UPDATED_AT = 'updateStoryCustomFieldValues';

export const UPDATE_STORY_FEATURED_IMAGE = 'updateStoryFeaturedImage';
export const PROCESSING_STORY_FEATURED_IMAGE = 'processingStoryFeaturedImage';
export const UPDATE_STORY_FEATURED_IMAGE_ALT = 'updateStoryFeaturedImageAlt';

export const UPDATE_STORY_LOCK = 'updateStoryLock';

export const UPDATE_STORY_SEO_TITLE = 'updateStorySeoTitle';
export const UPDATE_STORY_SEO_DESCRIPTION = 'updateStorySeoDescription';
export const UPDATE_STORY_SEO_SLUG = 'updateStorySeoSlug';
export const UPDATE_STORY_ANALYSIS = 'updateStoryAnalysis';
export const UPDATE_STORY_ANALYSIS_SCORE = 'updateStoryAnalysisScore';
export const SET_USED_TOPIC_SUGGESTIONS = 'setUsedTopicSuggestions';

export const UPDATE_SELECTED_ANALYSIS_ASSESSMENT = 'updateSelectedAnalysisAssessment';

export const SET_STORY_DUE_DATE = 'setStoryDueDate';

export const ADD_STORY_BRIEF = 'addStoryBrief';
export const UPDATE_STORY_BRIEF = 'updateStoryBrief';
export const DELETE_STORY_BRIEF = 'deleteStoryBrief';

export const UPDATE_STORY_SHARE_REPORT = 'updateStoryShareReport';

export const FETCH_STORIES_BY_DATE = 'fetchStoriesByDate';

export const FETCH_STORIES = 'fetchStories';
export const PREFETCH_STORIES = 'prefetchStories';
export const PROCESSING_FETCH_STORIES = 'processingFetchStories';

export const ADD_STORY_TAG = 'addStoryTag';
export const ADD_STORY_CATEGORY = 'addStoryCategory';
export const ADD_STORY_AUTHOR = 'addStoryAuthor';

export const UPDATE_STORY_APPROVAL_STATUS = 'updateStoryApprovalStatus';
